import React, {Component} from "react";
import moment from "moment";
import {Link, withRouter} from 'react-router-dom';

import withStyles from "@material-ui/core/styles/withStyles";

import Place from "@material-ui/icons/Place";

import EventIcon from "@material-ui/icons/Event";
import Primary from "components/Typography/Primary";

// core components
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// core components
import dashboardStyle from "PNH/assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import PnhLogo from "PNH/assets/img/pnh.png";
import hidden from "PNH/assets/img/hidden.webp";
import LockIcon from '@material-ui/icons/Lock';

const days = ["Ndz.", "Pon.", "Wt.", "Śr.", "Czw.", "Pt.", "Sob."];


class ActivityCardHidden extends Component {
    render() {
        const {classes, activity} = this.props;
        const url = activity.bought ? activity.url : '#'
        return (
            <div>
                <Card product style={{height: "100%"}}>
                    <CardHeader image>
                        <Link to={url}>
                            <img src={hidden} alt="..."/>
                        </Link>
                        <div style={{background: "linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) )"}}></div>

                        <LockIcon fontSize={"large"} style={{
                            left: 'auto',
                            right: 'auto',
                            width: '100%',
                            bottom: '40%',
                            top: 'auto',
                            position: "absolute",
                            boxShadow: "none",
                            filter: "brightness(0) invert(1)",
                        }}/>

                    </CardHeader>

                    <CardBody>
                        <Link to={url}>
                            <GridContainer
                                alignItems="center"
                                style={{minHeight: 60}}
                            >

                                <GridItem>
                                    <h4 className={classes.cardProductTitle}>
                                        <Place/> {activity.localization.city},{" "}
                                        {activity.localization.street}
                                    </h4>
                                </GridItem>

                            </GridContainer>
                        </Link>
                        <Link to={url}>
                            <p className={classes.cardProductDesciprion}>
                                <GridContainer

                                    justify="space-between"
                                    alignItems="center"
                                    style={{
                                        whiteSpace: "nowrap",
                                        fontSize: "14px",
                                    }}
                                >

                                    <GridItem xs={12}>
                                        <hr/>
                                    </GridItem>
                                    <GridItem xs style={{paddingRight: 0}}>
                                        <div className={` ${classes.iconText}`}>
                                            <EventIcon/> {days[moment(activity.date).day()]}{" "}
                                            {activity.date.split("-")[2]}.{activity.date.split("-")[1]}
                                        </div>
                                    </GridItem>


                                    <GridItem xs={12}>
                                        <hr/>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <Primary>
                                            <h3 style={{margin: 0}}>
                                                {activity.bought && <b>Wykupiony termin prywatny</b>}
                                                {!activity.bought && <b>Termin prywatny</b>}
                                            </h3>
                                        </Primary>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <hr/>
                                    </GridItem>

                                </GridContainer>


                            </p>
                        </Link>
                        <GridContainer>
                            <GridItem xs={12} style={{textAlign: 'center'}}>
                                Zorganizuj swój prywatny trening <Link to={"/wynajmij-obiekt"}
                                                                       target={"_blank"}> tutaj </Link>
                            </GridItem>
                        </GridContainer>
                    </CardBody>

                </Card>
            </div>
        )
            ;
    }
}

export default withRouter(withStyles(dashboardStyle)(ActivityCardHidden));
