import Badge from "@material-ui/core/Badge";
import React, {useCallback, useEffect, useState} from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {Api} from "../../../services/Api";
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import ListItem from "@material-ui/core/ListItem";


export const NotificationsList = ({classes, ...props}) => {
    const [count, setCount] = useState()
    const [unreadCount, setUnreadCount] = useState()
    const [notifications, setNotifications] = useState([])



    useEffect(() => {
        if(count === undefined) {
            console.log('2a')
            getNotificationsCount()
        } else {
            console.log('2b')
            setTimeout( getNotificationsCount, 60 * 1000)
        }

    }, [count]);

    const getNotificationsCount = () => {
        getUser()
    }

    const getUser = useCallback( () => {
        const token = localStorage.getItem('token');
        if (!token) {
            return null;
        }
        Api.users.show("current").then(({data}) => {
            if(!data.id) {
                return;
            }
            let counter = count || 0
            if (data.system_event_count.myAll > counter) {
                fetchNotifications()
                setCount(data.system_event_count.myAll)
                setUnreadCount(data.system_event_count.myUnread)
                window.setNotifications([
                    {
                        type: "success",
                        msg: `Masz nowe powiadomienie`,
                    },
                ])
            } else {
                console.log(1)
                setTimeout( getNotificationsCount, 60 * 1000)
            }
        })
    })

    const fetchNotifications = () => {
        Api.system_events.index().then(({data}) => {
            setNotifications(data.rows)
        })
    }


    return <CustomDropdown
        noLiPadding
        navDropdown
        hoverColor={"primary"}
        buttonProps={{
            simple: true,
            className: classes.navLink,
            color: "white",
            round: true,
            style: {
                color: "#555",
            },
        }}
        caret={false}
        buttonText={<Badge badgeContent={unreadCount} color="error">
            <NotificationsIcon className={classes.dropdownIcons}/>
        </Badge>}
        dropdownList={notifications.map((i) => {
            return <ListItem className={classes.listItem}>
                {i.message} <br/>
                {i.targetable?.label}
                <hr/>
            </ListItem>
        })}
    />

}