import React from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SecurityIcon from "@material-ui/icons/Security";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SportsVolleyballIcon from "@material-ui/icons/SportsVolleyball";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SportsHandballIcon from "@material-ui/icons/SportsHandball";
import { Link } from "react-router-dom";
import ListIcon from '@material-ui/icons/List';
import SportsFootballIcon from '@material-ui/icons/SportsFootball';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import {EmojiEmotions} from "@material-ui/icons";
import People from "@material-ui/icons/People";

export const EditProfileCard = ({ classes }) => (
  <Card>
    <CardBody>
      <h6 className={classes.cardCategory}>
        <AssignmentIndIcon />
      </h6>
      <h4 className={classes.cardTitle}>
        <Link to="/edit-profile">
          <h3>
            Dane Osobowe <ArrowForwardIosIcon style={{ paddingTop: 5 }} />
          </h3>
          <p>Dodawaj i zmieniaj dane osobowe</p>
          <p>&nbsp;</p>
        </Link>
      </h4>
    </CardBody>
  </Card>
);

export const EditPasswordCard = ({ classes }) => (
  <Card>
    <CardBody>
      <h6 className={classes.cardCategory}>
        <SecurityIcon />
      </h6>
      <h4 className={classes.cardTitle}>
        <Link to="/security-settings">
          <h3>
            Bezpieczeństwo <ArrowForwardIosIcon style={{ paddingTop: 5 }} />
          </h3>
          <p>Ustawienia hasła i prywatności</p>
          <p>&nbsp;</p>
        </Link>
      </h4>
    </CardBody>
  </Card>
);

export const BenefitsCard = ({ classes }) => (
    <Card>
      <CardBody>
        <h6 className={classes.cardCategory}>
          <CardGiftcardIcon />
        </h6>
        <h4 className={classes.cardTitle}>
          <Link to="/benefits-settings">
            <h3>
              Benefity <ArrowForwardIosIcon style={{ paddingTop: 5 }} />
            </h3>
            <p>Zarządzaj programami benefitowymi</p>
            <p>&nbsp;</p>
          </Link>
        </h4>
      </CardBody>
    </Card>
);

export const EditNotificationsCard = ({ classes }) => (
  <Card style={{ background: "rgba(0,0,0,0.1)" }}>
    <CardBody>
      <h6 className={classes.cardCategory}>
        <NotificationsIcon />
      </h6>
      <h4 className={classes.cardTitle}>
        <Link to="/notifications-settings" onClick={(e) => e.preventDefault()}>
          <h3>
            Powiadomienia <ArrowForwardIosIcon style={{ paddingTop: 5 }} />
          </h3>
          <p>Wybierz jakie powiadomienia </p>
          <p>chcesz od nas dostawać</p>
        </Link>
      </h4>
    </CardBody>
  </Card>
);
export const TransactionsCard = ({ classes }) => (
  <Card>
    <CardBody>
      <h6 className={classes.cardCategory}>
        <AccountBalanceWalletIcon />
      </h6>
      <h4 className={classes.cardTitle}>
        <Link to="/transakcje">
          <h3>
            Płatności <ArrowForwardIosIcon style={{ paddingTop: 5 }} />
          </h3>
          <p>Przeglądaj historia transakcji</p>
          <p>&nbsp;</p>
        </Link>
      </h4>
    </CardBody>
  </Card>
);

export const ActivitiesCard = ({ classes }) => (
  <Card>
    <CardBody>
      <h6 className={classes.cardCategory}>
        <SportsVolleyballIcon />
      </h6>
      <h4 className={classes.cardTitle}>
        <Link to="/wykupione-zajecia">
          <h3>
            Zapisy <ArrowForwardIosIcon style={{ paddingTop: 5 }} />
          </h3>
          <p>Przeglądaj dotychczasowe i</p>
          <p>przyszłe zajęcia</p>
        </Link>
      </h4>
    </CardBody>
  </Card>
);

export const ActivitiesObservedCard = ({ classes }) => (
  <Card>
    <CardBody>
      <h6 className={classes.cardCategory}>
        <ListIcon />
      </h6>
      <h4 className={classes.cardTitle}>
        <Link to="/moja-lista-rezerwowych">
          <h3>
            Lista rezerwowych <ArrowForwardIosIcon style={{ paddingTop: 5 }} />
          </h3>
          <p>Twoje zapisy na</p>
          <p>listę rezerwowych</p>
        </Link>
      </h4>
    </CardBody>
  </Card>
);

export const CreatedActivities = ({ classes }) => (
  <Card>
    <CardBody>
      <h6 className={classes.cardCategory}>
        <SportsHandballIcon />
      </h6>
      <h4 className={classes.cardTitle}>
        <Link to="/moje-zajecia">
          <h3>
          Zajęcia <ArrowForwardIosIcon style={{ paddingTop: 5 }} />
          </h3>
          <p>Dodane i koordynowane zajęcia, </p>
          <p>listy obecności oraz szablony</p>
        </Link>
      </h4>
    </CardBody>
  </Card>
);

export const CoordinatorActivities = ({ classes }) => (
  <Card>
    <CardBody>
      <h6 className={classes.cardCategory}>
        <ListAltIcon />
      </h6>
      <h4 className={classes.cardTitle}>
        <Link to="/koordynowane-zajecia">
          <h3>
            Koordynator <ArrowForwardIosIcon style={{ paddingTop: 5 }} />
          </h3>
          <p>Sprawdzaj listę obecności</p>
          <p>&nbsp;</p>
        </Link>
      </h4>
    </CardBody>
  </Card>
);

export const SportLevelCard = ({ classes }) => (
  <Card>
    <CardBody>
      <h6 className={classes.cardCategory}>
        <SportsFootballIcon />
      </h6>
      <h4 className={classes.cardTitle}>
        <Link to="/moje-poziomy">
          <h3>
            Poziom gry<ArrowForwardIosIcon style={{ paddingTop: 5 }} />
          </h3>
          <p>Zarządzaj swoim poziomem gry</p>
          <p>&nbsp;</p>
        </Link>
      </h4>
    </CardBody>
  </Card>
);


export const SubaccountsCard = ({ classes }) => (
    <Card>
      <CardBody>
        <h6 className={classes.cardCategory}>
          <EmojiEmotions />
        </h6>
        <h4 className={classes.cardTitle}>
          <Link to="/powiazane-konta">
            <h3>
              Powiązane konta<ArrowForwardIosIcon style={{ paddingTop: 5 }} />
            </h3>
            <p>Dodawaj dzieci i zarządzaj ich kontami </p>
            <p>&nbsp;</p>
          </Link>
        </h4>
      </CardBody>
    </Card>
);

export const GroupsCard = ({ classes }) => (
    <Card>
      <CardBody>
        <h6 className={classes.cardCategory}>
          <People />
        </h6>
        <h4 className={classes.cardTitle}>
          <Link to="/moje-grupy">
            <h3>
              Moje grupy<ArrowForwardIosIcon style={{ paddingTop: 5 }} />
            </h3>
            <p>Zarządzaj swoimi grupami i zaproszeniami </p>
            <p>&nbsp;</p>
          </Link>
        </h4>
      </CardBody>
    </Card>
);