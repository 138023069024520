import React, {useEffect, useState} from "react";
import Header from "../../../../components/Header/Header";
import classNames from "classnames";
import GridContainer from "../../../../../components/Grid/GridContainer";
import {Link} from "react-router-dom";
import {Footer} from "../../../../layouts/Footer";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import {makeStyles} from "@material-ui/core/styles";
import {Api} from "../../../../services/Api";
import {CompetitionGroupsForm, Functions} from "../Form";
import Accordion from "../../../../../components/Accordion/Accordion";
import ForGroupSlider from "./Activities/ForGroupSlider";
import GridItem from "../../../../../components/Grid/GridItem";
import CardBody from "../../../../../components/Card/CardBody";
import Card from "../../../../../components/Card/Card";
import CustomTabs from "../../../../../components/CustomTabs/CustomTabs";
import Face from "@material-ui/icons/Face";
import {UsersForm} from "./Players";
import {TeamsTab} from "./Teams";
import Button from "../../../../../components/CustomButtons/Button";
import EditIcon from "@material-ui/icons/Edit";
import GroupActivitiesTab from "./Activities";
import {ShareButton} from "./Components/share";
import {I18n} from "../../../../components/I18n";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";

const useStyles = makeStyles(presentationStyle);
export const CompetitionGroupsDashboard = ({...props}) => {
    const classes = useStyles();
    const [id, setId] = useState(props.match.params.id)
    const [group, setGroup] = useState({})
    const [activeTabs, setActiveTabs] = useState([])


    useEffect(() => {
        if (id) {
            Api.competition_groups.show(id).then((response) => {
                if (response.ok) {
                    setGroup(response.data)
                    let tabs = [{
                            tabName: "Wszyscy gracze",
                            tabContent: <UsersForm key={`g-${response.data.id}`} id={id} group={response.data}/>
                        },]

                        if(response.data.max_teams > 0) {
                            tabs.push({
                                tabName: "Drużyny",
                                tabContent:  <TeamsTab competition_group_id={response.data.id} />
                            })
                        }

                        tabs.push({
                            tabName: "Zajęcia",
                            tabContent:  <GroupActivitiesTab competition_group_id={response.data.id} {...props} />
                        })

                    setActiveTabs(tabs)


                } else {
                    props.history.push("/404")
                }
            });
        }
    }, [])



    return <>
        <Header brand="PNH" fixed color="white" classes={classes} {...props} />

        <div className={classNames(classes.main)} style={{marginTop: 75}}>
            <div className={classes.container}>
                <GridContainer alignItems={"center"}>
                    <GridItem xs={12} md={10}><h3
                        style={{
                            fontWeight: 900,
                            marginBottom: 40,
                            marginTop: 30,
                        }}
                    >
                        {" "}
                        {group.name}
                        <h6>
                            <Link to="/account-settings">Konto</Link> >{" "}
                            <Link to="/moje-grupy">Moje Grupy</Link> > {" "}
                            Szczegóły
                        </h6>
                    </h3></GridItem>
                    <GridItem xs={12} md={2} style={{textAlign: "right"}}>
                        <ShareButton group={group}/>
                        {/*<Button*/}
                        {/*    color="primary"*/}
                        {/*    size={"sm"}*/}
                        {/*    onClick={() => {*/}
                        {/*        window.location.href = `/moje-grupy/${group.id}/edycja`;*/}
                        {/*    }}*/}
                        {/*    key={`edit_${group.id}`}*/}
                        {/*    className={classes.actionButton}*/}
                        {/*>*/}
                        {/*    <EditIcon /> Edytuj*/}
                        {/*</Button>*/}
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} style={{marginBottom: '20px'}}>
                                        <h5>
                                            {!group.open && <span style={{fontSize: '14px',lineHeight: '15px', fontWeight: '500', marginTop: '-20px'}}> <LockOutlinedIcon style={{fontSize: '11px'}}/> Prywatna<br/></span>}
                                            <strong>{I18n.t(group.competition_group_type)}</strong>
                                        </h5>
                                    </GridItem>
                                    <GridItem xs={12} ><p>
                                        {group.description}
                                    </p></GridItem>
                                    <GridItem xs={6} md={3}>
                                        <b>Gracze:</b>
                                        <br/>
                                        {group.users?.length}/{group?.max_group_size}
                                    </GridItem>
                                    <GridItem xs={6} md={3}>
                                        <b>Drużyn:</b>
                                        <br/>
                                        {group.teams_count}
                                    </GridItem>
                                    <GridItem xs={6} md={3}>
                                        <b>Płeć:</b>
                                        <br/>
                                        {I18n.t(`addGroupForm${group.gender}`)}
                                    </GridItem>
                                    <GridItem xs={6} md={3}>
                                        <b>Dodano:</b>
                                        <br/>
                                        {group.created_at}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <Accordion nonBorder active={0} collapses={[
                                    {
                                        title: "Najbliższe zajęcia",
                                        content: <ForGroupSlider id={id}/>
                                    }
                                ]
                                }/>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem>
                        <CustomTabs
                            headerColor="primary"
                            tabs={activeTabs}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        </div>

        <Footer classes={classes} {...props} />
    </>
}