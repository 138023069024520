/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer";
// nodejs library that concatenates classes
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "PNH/components/Header/Header";
// sections for this page

import { Footer } from "PNH/layouts/Footer";

import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle";
import { Link } from "react-router-dom";
import MyGroupsTable from "./Table";
import {Invitations} from "./Invitations";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import Warning from "../../../../components/Typography/Warning";
import Card from "../../../../components/Card/Card";
import CardBody from "../../../../components/Card/CardBody";

class CompetitionGroupsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Header brand="PNH" fixed color="white" {...this.props} />

        <div className={classNames(classes.main)} style={{ marginTop: 75 }}>
          <div className={classes.container}>
            <GridContainer>
                <GridItem xs={10}>
              <h3
                style={{
                  fontWeight: 900,
                  marginBottom: 40,
                  marginTop: 30,
                }}
              >
                {" "}
                Grupy
                <h6>
                  <Link to="/account-settings">Konto</Link> >{" "}
                    Moje Grupy
                </h6>
              </h3>
                </GridItem>
                <GridItem xs={2}>
                    {/*<Button*/}
                    {/*    style={{float: "right", marginTop: '30px'}}*/}
                    {/*    color="primary"*/}
                    {/*    size={"sm"}*/}
                    {/*    onClick={() => {*/}
                    {/*        this.props.history.push(`/moje-grupy/dodaj`)*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Stwórz grupę*/}
                    {/*</Button>*/}

                </GridItem>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <Warning>Jeśli chcesz zapoznać się z naszą ofertą zajęć grupowych i drużynowych, skontaktuj się z obsługą klienta: kontakt@pilkanahali.pl</Warning>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
            <Invitations {...this.props} />
            <MyGroupsTable {...this.props} />
          </div>
        </div>

        <Footer {...this.props} />
      </>
    );
  }
}

CompetitionGroupsPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(presentationStyle)(CompetitionGroupsPage);
