/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/* eslint-disable */
import React from "react";
import {cloneDeep} from "lodash";
import Badge from "@material-ui/core/Badge";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import {Link} from "react-router-dom";
import {Api} from "../../services/Api";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown";
import Button from "components/CustomButtons/Button";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import headerLinksStyle from "assets/jss/material-kit-pro-react/components/headerLinksStyle";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Warning from "../../../components/Typography/Warning";
import {NotificationsList} from "./Notifications";

function HeaderLinks({...props}) {
    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const smoothScroll = (e, target) => {
        if (window.location.pathname === "/sections") {
            var isMobile = navigator.userAgent.match(
                /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
            );
            if (isMobile) {
                // if we are on mobile device the scroll into view will be managed by the browser
            } else {
                e.preventDefault();
                var targetScroll = document.getElementById(target);
                scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
            }
        }
    };
    const scrollGo = (element, to, duration) => {
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        var animateScroll = function () {
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };

    const {classes, dropdownHoverColor} = props;
    return (
        <List className={classes.list + " " + classes.mlAuto}>
            <ListItem className={classes.listItem}>
                <Link
                    to={"/szukaj"}
                    className={classes.navLink}
                >
                    Znajdź zajęcia
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link
                    to={props.data.currentUser.id ? "/dodaj-zajecia" : "/s/multisport-sporty-druzynowe-projekt-organizator"}
                    className={classes.navLink}
                >
                    Dodaj zajęcia
                </Link>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Link to="/wynajmij-obiekt" className={classes.navLink}>
                    Wynajmij obiekt
                </Link>
            </ListItem>

            <ListItem className={classes.listItem}>
                <Link to="/poznaj-nas" className={classes.navLink}>
                    Jak działamy
                </Link>
            </ListItem>

            {props.data.currentUser.id && (
                <ListItem className={classes.listItem}>
                    <NotificationsList classes={classes} {...props} />
                </ListItem>
            )}


            {props.data.currentUser.id && (
                <ListItem className={classes.listItem}>
                    <Link
                        to="/koszyk"
                        className={classes.navLink}
                        style={{marginRight: 0}}
                    >
                        <Badge badgeContent={props.basketItems} color="error">
                            <ShoppingCart className={classes.dropdownIcons}/>
                        </Badge>
                    </Link>
                </ListItem>
            )}

            {!props.data.currentUser.id && (
                <ListItem className={classes.listItem}>
                    <CustomDropdown
                        noLiPadding
                        navDropdown
                        hoverColor={"primary"}
                        buttonText={""}
                        buttonProps={{
                            simple: true,
                            className: classes.navLink,
                            color: "white",
                            round: true,
                            style: {
                                color: "black",
                            },
                        }}
                        buttonIcon={AccountCircleIcon}
                        dropdownList={[
                            <ListItem className={classes.listItem} style={{width: "100%"}}>
                                <Link to="#" onClick={e => {
                                    e.preventDefault();
                                    props.getStateUpdates().openRegisterModal()
                                }} className={classes.navLink}>
                                   <b> Zarejestruj się</b>
                                </Link>
                            </ListItem>,
                            <ListItem className={classes.listItem}>
                                <Link to="#" onClick={e => {
                                    e.preventDefault();
                                    props.getStateUpdates().openLoginModal()
                                }} className={classes.navLink}>
                                    Zaloguj się
                                </Link>
                            </ListItem>,

                            <ListItem  divider/>,
                            <ListItem className={classes.listItem}>
                                <Link to="/s/pomoc" className={classes.navLink}>
                                    Pomoc
                                </Link>
                            </ListItem>
                        ]}
                    />
                </ListItem>
            )}
            {props.data.currentUser.id && (
                <ListItem className={classes.listItem}>
                    <CustomDropdown
                        noLiPadding
                        navDropdown
                        hoverColor="primary"
                        buttonText={props.data.currentUser.first_name}
                        buttonProps={{
                            className: classes.navLink,
                            color: "white",
                            round: true,
                            style: {
                                color: "black",
                            },
                        }}
                        buttonIcon={AccountCircleIcon}
                        dropdownList={[
                            !props.data.currentUser.account_completed ? <Link onClick={() => {props.getStateUpdates().openCompleteAccountModal()}} className={classes.navLink + " "+ classes.warningColor}>
                                Dokończ rejestrację
                            </Link> : <></>,
                            <Link to="/moje-zajecia" className={classes.navLink}>
                                Organizator
                            </Link>,
                            <Link to="/koordynowane-zajecia" className={classes.navLink}>
                                Koordynator
                            </Link>,
                            <Link to="/wykupione-zajecia" className={classes.navLink}>
                                Zapisy
                            </Link>,
                            <Link to="/moja-lista-rezerwowych" className={classes.navLink}>
                                Lista rezerwowych
                            </Link>,
                            <Link to="/account-settings" className={classes.navLink}>
                                Konto
                            </Link>,
                            <Link to="#" className={classes.navLink} onClick={(e) =>
                                Api.users.logout().then(() => {
                                    localStorage.removeItem('token');
                                    localStorage.removeItem('autoAccountCompleteModal');
                                    const params = window.location.search ? "&" : "?";
                                    props.updateMasterState(
                                        {data: {currentUser: {}}},
                                        () => window.location.href = `${window.location.pathname}${window.location.search}${params}notification_msg=Wylogowano&notification_type=success`);
                                })
                            }>
                                Wyloguj
                            </Link>,
                        ]}
                    />
                </ListItem>
            )}
        </List>

    );
}

HeaderLinks.defaultProps = {
    hoverColor: "primary",
    data: {currentUser: {}},
};


export default withStyles(headerLinksStyle)(HeaderLinks);
