import React, {useCallback, useEffect, useState} from "react";
import {Footer} from "PNH/layouts/Footer";
import Header from "PNH/components/Header/Header";
import Parallax from "../../../components/Parallax/Parallax";
import classNames from "classnames";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle";
import {makeStyles} from "@material-ui/core/styles";
import {Api} from "../../services/Api";
import {withRouter} from "react-router-dom";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Button from "../../../components/CustomButtons/Button";
import {ShareButton} from "../Users/CompetitionGroups/Dashboard/Components/share";
import ForGroupSlider from "./Activities/ForGroupSlider";
import CardBody from "../../../components/Card/CardBody";
import {I18n} from "../../components/I18n";
import Card from "../../../components/Card/Card";
const useStyles = makeStyles(productStyle);
const GroupPage = ({...props}) => {
    const classes = useStyles();
    const [id, setId] = useState(props.data.urlParams.with_id)
    const [group, setGroup] = useState({})
    const user = props.data.currentUser

    const fetch = useCallback(() => {
        if(id){
            return Api.competition_groups.preview(id, {access_token: props.data.urlParams.access_token}).then(response => {
                if(response.ok) {
                    setGroup(response.data)
                } else {
                    props.history.push('/404')
                }
                return response
            })
        }else{
            props.history.push('/404')
        }

    }, [])

    useEffect(() => {fetch()}, []);

    const askForAccess = useCallback( () => {
        Api.competition_groups.ask_for_access(id, {}).then(async (response) =>{
            if(response.ok){
                await fetch().then(r => {
                    if(r.ok) {
                        if(r.data.role === 'pending') {
                            window.setNotifications([{ type: "success", msg: "Twoje zgłoszenie zostało przesłane i oczekuje na akceptację administratora grupy." }]);
                        }
                        if(r.data.role === 'player' || r.data.role === 'admin') {
                            window.setNotifications([{ type: "success", msg: "Dołączono do grupy" }]);
                        }
                    }
                })
            }
        })
    }, [])

    return <>
        <Header
            brand="PNH"
            fixed
            color="white"
            classe={classes}
            {...props}
        />
        <Parallax image={require("PNH/assets/img/banner.jpg")} className={classes.pageHeader}/>
        <div className={classNames(classes.section, classes.sectionWhite)}>
            <div className={classes.container}>
                <div className={classNames(classes.main, classes.mainHighRaised)} style={{padding: '15px'}}>
                    <GridContainer alignItems={"center"}>
                        <GridItem xs={12} md={9} style={{marginTop: '0'}}>
                            <h2 style={{marginTop: '0'}}>
                                {!group.open && <span style={{fontSize: '14px',lineHeight: '15px', fontWeight: '500', marginTop: '-20px'}}> <LockOutlinedIcon style={{fontSize: '11px'}}/> Prywatna<br/></span>}
                                <strong style={{lineHeight: '35px'}}>{group.name}</strong>
                            </h2>
                        </GridItem>
                        <GridItem xs={12} md={3} style={{marginTop: '0'}}>
                            {group.role == 'guest' && user.id && group.open && <Button fullWidth={true} color={"primary"} onClick={()=> askForAccess()}>Dołącz</Button> }
                            {group.role == 'guest' &&  user.id && !group.open && <Button fullWidth={true} color={"primary"} onClick={()=> askForAccess()} >Poproś o dołączenie</Button> }
                            {group.role == 'player' && user.id   && <Button fullWidth={true} color={"primary"}  href={`/moje-grupy/${group.id}`} >Przejdź do grupy</Button> }
                            {group.role == 'pending' && user.id  && <Button fullWidth={true} color={"primary"} disabled={true} >Oczekuje akcpetacji</Button> }
                            {group.role == 'admin' && user.id   && <ShareButton group={group}/>}
                            {/*{group.role == 'admin' && user.id   && <Button href={`/moje-grupy/${group.id}/edycja`} style={{marginRight: '10px'}} simple justIcon link color={"primary"}  size={"sm"}><EditIcon /></Button> }*/}
                            {group.role == 'admin' && user.id  && <Button href={`/moje-grupy/${group.id}`} color={"primary"} size={"sm"}>Przejdź do grupy</Button> }
                        </GridItem>
                        <GridItem xs={12} style={{fontSize: '14px', fontWeight: '700', color: '777'}}>{group.description}</GridItem>
                        <GridItem xs={12}>
                            <Card>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <h5><strong>Szczegóły</strong></h5>
                                        </GridItem>

                                        <GridItem xs={6} md={3}>
                                            <b>Gracze:</b>
                                            <br/>
                                            {group.players}/{group?.max_group_size}
                                        </GridItem>
                                        <GridItem xs={6} md={3}>
                                            <b>Drużyn:</b>
                                            <br/>
                                            {group.teams_count}
                                        </GridItem>
                                        <GridItem xs={6} md={3}>
                                            <b>Płeć:</b>
                                            <br/>
                                            {I18n.t(`addTeamForm${group.gender}`)}
                                        </GridItem>
                                        <GridItem xs={6} md={3}>
                                            <b>Dodano:</b>
                                            <br/>
                                            {group.created_at}
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>

                </div>
            </div>
        </div>

        {group.closest_activities && group.closest_activities.length > 0 && <div className={classes.container}>
            <h3 className={classNames(classes.title, classes.textCenter)}>
               Najbliższe zajęcia
            </h3>
            <GridContainer>
                <GridItem xs={12} >
                      <ForGroupSlider activities={group.closest_activities} {...props}/>
                </GridItem>
            </GridContainer>
        </div>}

        <Footer classes={classes} {...props} />
    </>
}

export default withRouter(GroupPage);
